import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Image } from "../components"
import config from "../siteConfig"

const AboutPage = () => (
  <Layout>
    <Seo title="About" />

    <div className="container">
      <header className="about-header">
        <div className="about-avatar">
          <Image
            className="about-avatar-image"
            src="avatar.jpg"
            alt={config.siteTitle}
          />
        </div>
        <div className="about-content">
          <h1 className="about-title">{config.siteTitle}</h1>
          <p className="about-subtitle">{config.siteDescription}</p>
          <p className="about-text">
            Writing on national security, terrorism and current affairs that
            interest me.
          </p>
        </div>
      </header>

      <blockquote className="about-blockquote">
        If you know the enemy and know yourself, you need not fear the result of
        a hundred battles. If you know yourself but not the enemy, for every
        victory gained you will also suffer a defeat. If you know neither the
        enemy nor yourself, you will succumb in every battle.
        <footer>Sun Tzu</footer>
      </blockquote>
    </div>
  </Layout>
)

export default AboutPage
